<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm id="challenge_form" method="POST" enctype="multipart/form-data">
            <CRow>
              <CCol md="8">
                <CInput
                  label="Nombre:"
                  type="name"
                  name="name"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <CCol md="4">
                <CSelect
                  label="Tipo"
                  id="type"
                  name="type"
                  :value.sync="$v.form.type.$model"
                  :options="typeOptions"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Descripción:"
                  type="description"
                  name="description"
                  :lazy="false"
                  :value.sync="$v.form.description.$model"
                  :isValid="checkIfValid('description')"
                  placeholder=""
                  autocomplete="given-description"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow v-if="form.type == 'boxes'">
              <CCol md="12">
                <CSelect
                  label="Mécanica del Concurso"
                  id="mechanic"
                  name="mechanic"
                  :value.sync="$v.form.mechanic.$model"
                  :options="mechanicBoxesOptions"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                    type="file"
                    name="image"
                    id="image"
                    label="Imagen del concurso:"
                    :lazy="false"
                    :value.sync="$v.form.image.$model"
                />
              </CCol>
            </CRow>
            <CRow v-if="form.type == 'video' && form.id == 0">
              <CCol md="12">
                <CInput
                  label="Link de Youtube:"
                  type="youtube"
                  name="youtube"
                  :lazy="false"
                  :value.sync="$v.form.youtube.$model"
                  :isValid="checkIfValid('youtube')"
                  placeholder=""
                  autocomplete="given-youtube"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol :md="6">
                <div role="group" class="form-group" style="margin: -10px 0px 10px">
                  <label style="padding-top: 10px;">Inicio de la Promoción:</label>
                  <v-date-picker
                    class="date-picker"
                    v-model="$v.form.start_date.$model"
                    :isValid="checkIfValid('start_date')"
                    locale="es-MX" size="sm"
                  />
                </div>
              </CCol>
              <CCol :md="6">
                <div role="group" class="form-group" style="margin: -10px 0px 10px">
                  <label style="padding-top: 10px;">Fin de la Promoción:</label>
                  <v-date-picker
                    class="date-picker"
                    v-model="$v.form.end_date.$model"
                    :isValid="checkIfValid('end_date')"
                    locale="es-MX" size="sm"
                  />
                </div>
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, numeric, minLength, helpers, requiredIf, requiredUnless } from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'

import moment from 'moment';
import moment_timezone from 'moment-timezone';

import c from 'moment-timezone';
import ws from '../../services/challenges';
import store from '../../store'

export default {
  name: 'ChallengeModal',
  props: {

  },
  components: { 'v-date-picker': DatePicker },
  data () {
    return {
      showModal: false,
      title: "Nuevo Concurso",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      typeOptions: [
        {label: 'Capacitación', value: 'video'},
        {label: 'Venta Mínima', value: 'sales'},
        {label: 'Clientes Mínimos', value: 'customers'},
        {label: 'Mínimo de Cajas', value: 'boxes'}
      ],
      mechanicBoxesOptions: [
        {label: 'Numero Total de Cajas Vendidas', value: 'total'},
        {label: 'Rangos de Cajas Vendidas', value: 'ranges'},
        {label: 'Individual Por Cada Caja Vendida', value: 'individual'}
      ],
      link: '',
      item: false
    }
  },
  mounted: async function() {
    
  },
  methods: {
    storeModal () {
      this.showModal = true;
      this.color = "info";
      this.title = 'Nuevo Concurso';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          code: '',
          name: '',
          description: '',
          image: null,
          type: 'video',
          mechanic: 'total',
          youtube: '',
          points: '',
          start_date: new moment_timezone().tz("America/Mexico_city").startOf('month').toDate(),
          end_date: new moment_timezone().tz("America/Mexico_city").endOf("month").toDate()
      }
    },
    updateModal (item) {
      this.showModal = true;
      this.color = "warning";
      this.title = 'Editar Concurso';
      this.isEdit = true;
      this.submitted = false;

      this.item = item;

      this.form = {
          id: item.id,
          code: item.code,
          name: item.name,
          description: item.description,
          type: item.type,
          mechanic: item.mechanic,
          points: item.points,
          start_date: new moment_timezone(item.start_date, "YYYY-MM-DD").tz("America/Mexico_city").toDate(),
          end_date: new moment_timezone(item.end_date, "YYYY-MM-DD").tz("America/Mexico_city").toDate(),
          youtube: item.youtube
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        let start_date = this.form.start_date;
        let end_date = this.form.end_date;

        this.form.start_date = new moment_timezone(this.form.start_date).format("YYYY-MM-DD");
        this.form.end_date = new moment_timezone(this.form.end_date).format("YYYY-MM-DD");

        const form = document.getElementById("challenge_form");
        const formData = new FormData(form);

        formData.append("id", this.form.id);
        formData.append("start_date", new moment_timezone(this.form.start_date).format("YYYY-MM-DD"));
        formData.append("end_date", new moment_timezone(this.form.end_date).format("YYYY-MM-DD"));

        if(this.form.id > 0){
          this.$emit("update", formData );
        }
        else{
          this.$emit("store", formData );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          code: '',
          name: '',
          description: '',
          type: 'video',
          mechanic: 'total',
          image: null,
          youtube: '',
          points: 0,
          start_date: new moment_timezone().tz("America/Mexico_city").startOf('month').toDate(),
          end_date: new moment_timezone().tz("America/Mexico_city").endOf("month").toDate()
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    redirectLink(link){
      window.location = link;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      description: {
        required,
        minLength: minLength(3)
      },
      code: {},
      type: {
        required
      },
      mechanic: {
        required: requiredIf(function(form){
          return form.type == 'boxes' && form.id == 0
        })
      },
      youtube: {
        required: requiredIf(function(form){
          return form.type == 'video' && form.id == 0
        })
      },
      points: {
        
      },
      start_date: {
        
      },
      end_date: {
        
      },
      image:{

      }
    }
  },
}
</script>